import React from "react";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import LargerHeadingSection from "../components/uber_about_components/LargerHeadingSection";
import MissionStatement from "../components/uber_about_components/MissionStatement";
import Footer from "../components/layout/footer/Footer";

export default function UberAbout() {
    return (
        <Layout>
            <Navbar />
            <LargerHeadingSection />
            <MissionStatement />
            <Footer space />
        </Layout >
    );
}