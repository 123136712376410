import React from "react";

const FeaturesTwo = () => {
  const features = [
    {
      "title": "Enhanced Mobile App Experience",
      "description": "Easeup offers two dedicated mobile apps for clients and handymen, providing a comprehensive and user-friendly platform."
    },
    {
      "title": "Uninterrupted Service Availability",
      "description": "Easeup operates 24/7, including weekends and holidays, ensuring continuous access to services for clients."
    },
    {
      "title": "Automated Chatbot Assistance",
      "description": "Easeup incorporates a chatbot feature, streamlining customer inquiries and support with instant and efficient responses."
    },
    {
      "title": "Transparency through Visual Portfolio",
      "description": "Handymen on Easeup showcase their work through pictures or videos, enabling clients to make informed decisions based on demonstrated expertise."
    },
    {
      "title": "Verified User Profiles",
      "description": "Easeup verifies user identities and backgrounds for both clients and handymen, establishing a secure and trustworthy marketplace."
    }
  ]

  return (
    <>
      <section id="features" className="feature-section ptb-100">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-4">
              <div className="download-img">
                <img
                  src="assets/img/mockup2.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-md-7">
              <div className="feature-contents section-heading">
                <h2>
                  Why Choose Easeup
                </h2>
                <p className="text-justify">
                  At EaseUp, we understand how stressful it can be to find a reliable and trustworthy handyman for your home repairs and maintenance needs.
                  That's why we offer a stress-free solution by connecting clients to skilled handymen in their local area.
                  We take the hassle out of the search process, so you can focus on other important aspects of your life.
                  Our platform provides a safe and secure environment for clients and handymen to connect and conduct business.

                </p>
                <p className="text-justify">
                  Our handymen are thoroughly vetted and must pass a background check before being listed on our platform, ensuring that you receive top-quality services from qualified professionals.
                  With our easy-to-use payment system, you can rest assured that you're paying for services rendered and that handymen receive payment on time.
                  At EaseUp, we strive to provide our clients with the best possible service while prioritising their safety and peace of mind.
                </p>


                <table className="table table-info table-striped table-bordered table-hover table-responsive shadow-sm rounded">
                  <tr>
                    <th scope="col">Feature</th>
                    <th scope="col">Description</th>
                  </tr>
                  <tbody>
                    {
                      features.map((feature) => {
                        return (
                          <tr>
                            <td>{feature.title}</td>
                            <td>{feature.description}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FeaturesTwo;
