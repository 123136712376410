import React from "react";
import AppRoutes from "./routes/Routes";
import { Analytics } from '@vercel/analytics/react';

const App = () => {
  return (
    <div>
      <AppRoutes />
      <Analytics />
    </div>
  );
};

export default App;
