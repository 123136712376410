import React from "react";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";

// Create styles

export default function TermsAndConditions() {

    return (
        <Layout>
            <Navbar />

            <div className="WordSection1 container p-5 mt-5 text-justify">
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '99%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ fontSize: '36.0pt', msoBidiFontSize: '12.0pt', lineHeight: '99%', msoBidiFontFamily: 'Tahoma' }}>Easeup
                    Limited Company Terms of Service </span></b></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '12.75pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ msoBidiFontFamily: 'Tahoma' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></b></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.25pt', marginLeft: '.6pt' }}><span lang="EN">Last updated: January 30, 2023 </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '11.75pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ msoBidiFontFamily: 'Tahoma' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></b></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.75pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></b></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.55pt', marginLeft: '.6pt' }}><span lang="EN">These Terms of Service form a legally binding
                    agreement between you and Easeup regarding your use of the Easeup platform,
                    which includes the Easeup website, mobile app, and related services,
                    information, and communications. The Easeup platform is referred to
                    collectively as "Easeup."</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.55pt', marginLeft: '.6pt' }}><span lang="EN">The way that personal data you submit to the
                    Easeup platform or that we collect about you is used is governed by our Privacy
                    Policy. You can access a copy of the Privacy Policy by clicking here. By using
                    the Easeup platform, you acknowledge that you have reviewed the Privacy Policy.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.55pt', marginLeft: '.6pt' }}><span lang="EN">By registering and continuing to use the Easeup
                    platform, you are agreeing to all the terms and conditions in these Conditions
                    of Service, the Privacy Policy, and the Easeup Happiness Pledge, as well as any
                    future amendments or additions to this agreement that may be published. If you
                    do not agree to any future changes to the agreement, you must deactivate your
                    account and stop using the Easeup platform.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.6pt', marginLeft: '.6pt' }}><span lang="EN">The Privacy Policy and the Happiness Pledge are
                    part of these Terms of Service and together are referred to as the
                    "Agreement."</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '-.1pt', marginBottom: '12.25pt', marginLeft: '-.25pt', lineHeight: '99%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ fontSize: '12.5pt', msoBidiFontSize: '12.0pt', lineHeight: '99%', msoBidiFontFamily: 'Tahoma' }}>If you do not agree to be bound by the terms of
                    this agreement and follow them, you are not allowed to use or access the Easeup
                    platform</span></b><span lang="EN">.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '-.1pt', marginBottom: '12.25pt', marginLeft: '-.25pt', lineHeight: '99%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ fontSize: '12.5pt', msoBidiFontSize: '12.0pt', lineHeight: '99%', msoBidiFontFamily: 'Tahoma' }}>Please be aware that Section 28 of these terms of
                    service contains provisions specific to different jurisdictions and outlines
                    how users and Easeup can resolve disputes. This arbitration agreement requires
                    you to submit any disputes or claims against Easeup to binding and final arbitration
                    on an individual basis, with limited exceptions. It is important that you read
                    this section carefully as it affects your legal rights, including your right to
                    opt out of arbitration if it is applicable.</span></b><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '-.1pt', marginBottom: '12.25pt', marginLeft: '-.25pt', lineHeight: '99%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ fontSize: '12.5pt', msoBidiFontSize: '12.0pt', lineHeight: '99%', msoBidiFontFamily: 'Tahoma' }}>By acknowledging these terms of service and/or
                    using the Easeup platform, you are confirming that you have read, understand,
                    and agree to be bound by them, and you accept all their terms without any
                    limitation or qualification.</span></b><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '15.0pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.4pt', marginLeft: '.6pt' }}><span lang="EN">Some key points to note in these terms of
                    service include:</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">You must be at least 18 years old
                    and legally able to enter contracts to use or access the Easeup platform
                    (Section 2). </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">The technology for the Easeup
                    platform is provided "as is" without any warranties (Section 17). </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Easeup does not provide any
                    warranties and is not liable for any actions or tasks performed on the platform
                    (Section 17). </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Easeup does not supervise, direct,
                    control, or monitor a handyman's work or tasks (Section 1). </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Clients are solely responsible for
                    determining if the handyman they hire is qualified to perform the task
                    (Sections 1 and 23). </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Handymen are independent
                    contractors of clients and are not employees, independent contractors, or
                    service providers of Easeup (Section 1). </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '25.0pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">You agree to indemnify and hold
                    Easeup harmless from any claims arising from your use or inability to use the
                    Easeup platform or content submitted from your account to the platform (Section
                    18) </span></p>
                <h1 style={{ marginLeft: '.6pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}The Easeup Platform Connects Handymen and
                    Clients<span style={{ fontSize: '30.0pt', msoBidiFontSize: '12.0pt', lineHeight: '107%', fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.8pt', marginLeft: '.6pt' }}><span lang="EN">The Easeup Platform is a web and app-based
                    service that connects clients with handymen. Clients are individuals or
                    businesses that need short-term services, also known as "tasks,"
                    while handymen are businesses that offer these services. Both clients and
                    handymen are referred to as "users." If a client and handyman agree
                    on the terms of a task, they form a service agreement directly with each other.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '-.1pt', marginBottom: '12.25pt', marginLeft: '-.25pt', lineHeight: '99%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ fontSize: '12.5pt', msoBidiFontSize: '12.0pt', lineHeight: '99%', msoBidiFontFamily: 'Tahoma' }}>Handymen on the Easeup platform are independent
                    business owners and contractors of clients, not employees or agents of Easeup.
                    Easeup does not perform tasks and is not responsible for the work of handymen.
                    It operates as an online marketplace that connects clients with handymen who
                    are willing to perform various tasks. Easeup does not supervise, direct, or
                    control the work of handymen and does not assume any responsibility or
                    liability for their work, including any warranties or guarantees of the quality
                    or suitability of their services.</span></b><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.8pt', marginLeft: '.6pt' }}><span lang="EN">The Easeup platform may refer to handymen as
                    being licensed, credentialed, "badged," "reliable,"
                    "elite," "great value," "background checked," or
                    "vetted," but this simply means that they have completed the
                    necessary account registration process or met certain criteria and does not
                    necessarily reflect their skills or qualifications. These descriptions are not
                    endorsements or guarantees by Easeup and should not be taken as such. They are
                    provided as information for clients to use when evaluating the suitability of
                    handymen for the tasks they need. Clients are responsible for making their own
                    decisions about the identity and suitability of handymen they choose to work
                    with through the Easeup platform.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '25.05pt', marginLeft: '.6pt' }}><span lang="EN">The Easeup platform facilitates connections
                    between users for the completion of tasks, but it is not responsible for the
                    performance or communication of users, the quality or timing of tasks, or the
                    actions or omissions of any user. Easeup does not control or guarantee the
                    suitability, reliability, timeliness, or accuracy of tasks requested or
                    services provided by users, or the communication between users, whether online
                    or offline. Easeup also does not endorse or guarantee the accuracy of any
                    ratings or reviews provided by users about each other.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <h1 style={{ marginLeft: '.6pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Handyman Background Checks and User
                    Representations and Warranties<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span>Handyman Background Checks<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.8pt', marginLeft: '.6pt' }}><span lang="EN">Handymen may be subject to a review process,
                    including identity verification and criminal background checks, before they can
                    use the Easeup platform. However, Easeup cannot guarantee the accuracy or
                    reliability of this information and is not responsible for verifying the
                    identity of users. When interacting with other users, it is important to use
                    caution and protect your personal safety, data, and property, as you would with
                    any unfamiliar person. Easeup is not liable for any false or misleading
                    statements made by users of the platform.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '-.1pt', marginBottom: '25.1pt', marginLeft: '-.25pt', lineHeight: '99%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ fontSize: '12.5pt', msoBidiFontSize: '12.0pt', lineHeight: '99%', msoBidiFontFamily: 'Tahoma' }}>Easeup and its affiliates, including their
                    directors, officers, shareholders, agents, subsidiaries, attorneys,
                    representatives, insurers, employees, successors, and assigns, are not
                    responsible or liable for the conduct, acts, or omissions, online or offline,
                    of any user of the Easeup platform. By using the platform, you release Easeup
                    and its affiliates from any and all liability, claims, demands, or damages of
                    any kind arising from your use of the platform, to the extent permitted by law.
                    This includes known and unknown, suspected and unsuspected, and disclosed and
                    undisclosed claims.</span></b><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h2 style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '4.35pt', marginLeft: '.85pt', textIndent: '0cm' }}><span style={{ fontSize: '21.0pt', msoBidiFontSize: '12.0pt', lineHeight: '107%' }}>User Representations and Warranties</span><span style={{ fontSize: '21.0pt', msoBidiFontSize: '12.0pt', lineHeight: '107%', fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h2>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '2.35pt', marginBottom: '13.1pt', marginLeft: '.6pt', lineHeight: '105%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ msoBidiFontFamily: 'Tahoma' }}>All users of the Easeup platform
                    must:</span></b><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Be at least 18 years old and
                    legally able to enter into contracts. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Have the right and authority to
                    enter into this agreement and abide by its terms. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Have read, understood, and agreed
                    to the terms of service, and privacy policy applicable to the location where
                    the task will be performed. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Only perform tasks in cities where
                    the Easeup platform is available. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Respect the privacy, property, and
                    data protection rights of other users and not record any tasks or interactions
                    without prior written consent. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Fulfill their commitments to other
                    users, communicate clearly and promptly, be present and available at the agreed
                    upon time, and use the designated or approved third party payment service
                    provider for payment. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Act professionally and responsibly
                    in their interactions with other users. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Use their real name or business
                    name on their profile. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Follow all applicable laws and act
                    in good faith when using the Easeup platform. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Not use the platform to purchase
                    or deliver alcohol or other controlled or illegal substances or services. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">If using the platform on behalf of
                    a company or organization, have the authority to act on their behalf and bind
                    them to this agreement. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.5pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Fully and promptly disclose any
                    potential conflicts of interest or motivations that Easeup may wish to know
                    about, including any journalistic, academic, investigative, or unlawful
                    purposes. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '2.35pt', marginBottom: '13.1pt', marginLeft: '.6pt', lineHeight: '105%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ msoBidiFontFamily: 'Tahoma' }}>In addition to the requirements for
                    all users, handymen using the Easeup platform must:</span></b><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Operate as a sole proprietorship,
                    partnership, limited liability company, limited liability partnership,
                    corporation, or other business entity. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Be engaged in an independently
                    established business similar to the services they offer on the platform and
                    have their own independent clientele. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Have the right to work in the
                    country. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Have any required business licenses
                    or tax registrations in the jurisdiction where they will be performing tasks. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Obtain any necessary licenses,
                    permits, or registrations before offering services and undertaking tasks. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Have the insurance required to
                    operate their business and provide their services. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Use their real name or business
                    name and a current photo on their profile. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Honor their commitments to other
                    users, including responding to invitations promptly, performing tasks as agreed
                    upon with clients, and providing high-quality services to clients. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '24.7pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Only offer and provide services
                    for which they have the necessary skills and expertise, and do so safely and in
                    accordance with all applicable laws. </span></p>
                <h1 style={{ marginLeft: '.6pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Contract between Clients and Handymen<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.6pt', marginLeft: '.6pt' }}><span lang="EN">A legally binding contract between a client and
                    a handyman, known as a service agreement, is formed when they agree on the
                    terms of a task. The service agreement includes the terms set forth in this
                    section of the agreement, the engagement terms agreed upon on the Easeup
                    platform, and any other contractual terms agreed upon by the handyman and
                    client, if they do not conflict with the terms of the service agreement or
                    expand Easeup's obligations or restrict its rights under the agreement. Easeup
                    is not a party to any service agreement and the formation of such an agreement
                    does not create an employment or other service relationship between Easeup and
                    the handyman or between the client and the handyman. Users do not have the
                    authority to enter contracts on behalf of Easeup.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.55pt', marginLeft: '.6pt' }}><span lang="EN">Handymen may engage assistants, helpers,
                    subcontractors, or other personnel to help them perform tasks, if they have
                    been registered through the Easeup platform and the client has approved their
                    involvement. The handyman is responsible for the actions and omissions of these
                    assistants and for paying their compensation, benefits, and expenses, as well
                    as any required tax withholdings. Clients are responsible for confirming that
                    any assistants are registered handymen on the platform.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '26.45pt', marginLeft: '.6pt' }}><span lang="EN">Clients have the discretion to decide whether
                    they will be present when a task is being performed or not. If someone else
                    books the task on their behalf or is present in their place, they are
                    appointing that person as their agent and the handyman may take direction from
                    them as if it were coming from the client. If a client's agent is accessing and
                    using the Easeup platform on behalf of a client, they must have the authority
                    to act as the client's agent and bind them to the terms of the platform. The
                    client's agent may waive the client's scoping, direction, or instruction of the
                    handyman's work or the task performance in the client's stead. The client is
                    responsible and liable for any direction or waivers made by their agent as if
                    they had made them themselves. The client is also responsible for the actions
                    and omissions of their agent. The client is required to pay the handyman in
                    full for all task services. Both the client and the handyman must adhere to the
                    terms of the service agreement and this agreement during the task engagement,
                    performance, and completion.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '29.35pt', textIndent: '-29.25pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>4.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Payment<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.8pt', marginLeft: '.6pt' }}><span lang="EN">Users and handymen are required to pay a
                    monthly fee to access and use the services provided by the app. In addition,
                    they have the option to enroll in an insurance program offered by EaseUp. The
                    clients are responsible for paying for all services rendered by the handymen.
                    In case of non-payment by the client, EaseUp does not hold responsibility for
                    compensating the handymen and it is the responsibility of the client and
                    handyman to resolve the issue. To utilize the services, both clients and
                    handymen must provide their payment information to EaseUp.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '25.0pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <h1 style={{ marginLeft: '.6pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>5.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Contests, Gift Cards, and Promotional Codes<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '26.25pt', marginLeft: '.6pt' }}><span lang="EN">Easeup may occasionally offer promotional
                    opportunities and contests to users. These promotions will be managed solely by
                    Easeup and may be altered or removed at any time without prior notice. Any
                    liability that Easeup or its affiliates or corporate partners may have because
                    of these promotions and contests will be subject to the limitations outlined in
                    section 16 of the terms of service. Easeup gift cards and promotional codes may
                    be used to pay for task payments and Easeup service charges and Trust &amp;
                    Support fees, but they cannot be used to pay for reimbursement of out-of-pocket
                    expenses or tips for tasks completed through the Easeup platform.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h2 style={{ marginLeft: '.6pt' }}>A. Promo Codes<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h2>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '.6pt' }}><span lang="EN">Promo Codes are discounts offered by Easeup on
                    task payments, service charges, and/or Trust &amp; Support fees. They do not
                    affect the amount of the task payment that a handyman receives. Promo Codes are
                    intended for promotional purposes only and do not create a relationship between
                    Easeup, the handyman, or the client, or constitute wages, fees, or other payments
                    to the handyman. You must use Promo Codes in accordance with their terms and
                    conditions. A Promo Code for new users can only be used once per user,
                    regardless of the email address used during registration. Easeup reserves the
                    right to revoke or deduct credits or other benefits obtained by a Promo Code if
                    the use or redemption of the Promo Code was in error, fraudulent, illegal, or
                    otherwise violated the Promo Code terms and conditions or the agreement.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h2 style={{ marginLeft: '.6pt' }}>B. Gift Cards<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h2>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '28.0pt', marginLeft: '.6pt' }}><span lang="EN">Gift cards can only be used for tasks performed
                    on the Easeup platform, cannot be replaced if lost or stolen, and do not
                    expire. They have no cash value and cannot be exchanged for cash, except as
                    required by law. Gift cards must be entered directly into a client account;
                    they cannot be used directly as a payment method by handymen. A gift card
                    cannot be used in combination with other gift cards, gift certificates, or
                    promo codes. There is no credit card, credit line, overdraft protection, or
                    deposit account associated with a gift card, and you cannot add value to it at
                    this time. If a purchase using a gift card exceeds the balance on the card, the
                    difference must be paid by another method. Unused gift card balances are not
                    transferable. Easeup reserves the right to correct the balance on a gift card
                    if it believes there has been a billing error, and to limit the number of gift
                    cards that can be purchased by any person or entity, or to cancel a gift card
                    if it was obtained through fraudulent or unauthorized means. Using a gift card
                    is subject to this agreement and constitutes acceptance of the terms and
                    conditions. You must comply with all gift card terms and conditions.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '29.35pt', textIndent: '-29.25pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>6.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Mobile App Updates and Upgrades<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '27.95pt', marginLeft: '.6pt' }}><span lang="EN">By installing the App, you agree to allow the
                    App and any updates or upgrades released through the Easeup Platform to be
                    installed on your device. These updates or upgrades may cause your device to
                    automatically communicate with Easeup's servers to provide the App's
                    functionality and track usage metrics. They may also change preferences or data
                    related to the App that are stored on your device, and may collect personal
                    information as described in our Privacy Policy. You can uninstall the App at
                    any time.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '29.35pt', textIndent: '-29.25pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>7.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Deactivation and Suspension<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.6pt', marginLeft: '.6pt' }}><span lang="EN">Easeup may temporarily suspend your access to
                    the Easeup Platform while it investigates a potential breach of this Agreement
                    by you. If Easeup determines that you have breached a provision of this
                    Agreement (referred to as a "User Breach"), it may deactivate your
                    account or limit your use of the Easeup Platform. Easeup will provide you with
                    written notice of this determination as required by law, unless it believes
                    that your account has been compromised and that the notice would be sent to the
                    wrong person, or if notice would be otherwise counterproductive or pose a risk
                    to safety. If you wish to appeal this determination, please contact support<u style={{ textUnderline: 'black' }}>@easeupgh.tech </u><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span>within 14 days of receiving the notice,
                    stating the grounds for your appeal.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '.6pt' }}><span lang="EN">If Easeup suspends or deactivates your account
                    or limits your use of the Easeup Platform in accordance with this section, you
                    are not allowed to register or create a new account under your own or any third
                    party's name, including a fake or borrowed name, even if you are acting on
                    behalf of the third party.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.5pt', marginLeft: '.6pt' }}><span lang="EN">Even if your right to use the Easeup Platform
                    is suspended, terminated, or limited, this Agreement will still be enforceable
                    against you. Easeup reserves the right to take legal action as allowed by this
                    Agreement.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.55pt', marginLeft: '.6pt' }}><span lang="EN">Easeup reserves the right to modify or discontinue
                    any part of the Easeup Platform at its discretion, either temporarily or
                    permanently. It will provide you with notice of any such changes as required by
                    law. Easeup will not be liable to you for any modification or discontinuation
                    of any part of the Easeup Platform, to the extent permitted by law. Easeup also
                    has the right to prevent anyone from completing registration as a Handyman if
                    their presence on the platform may threaten its safety or integrity, or if
                    their registration is necessary to address any other reasonable business
                    concern.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '24.85pt', marginLeft: '.6pt' }}><span lang="EN">You can terminate this Agreement at any time by
                    stopping all use of the Easeup Platform and deactivating your account.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '.0pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>8.
                </span></span>{/*[endif]*/} Account, Password, Security, and Telephone
                    Communications<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.55pt', marginLeft: '.6pt' }}><span lang="EN">To use the Easeup Platform, you must register
                    with Easeup and create an account. You are responsible for keeping the login
                    information, password, and account number provided to you by Easeup
                    confidential. You are fully and solely responsible for all activity that occurs
                    under your password or account, even if you did not authorize it. Easeup has no
                    control over the use of any user's account and explicitly denies any liability
                    resulting from it. If you suspect that an unauthorized party may be using your
                    password or account, or if you suspect any other security breach, you agree to
                    notify Easeup immediately.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.35pt', marginLeft: '.6pt' }}><span lang="EN">You understand that telephone calls to or from
                    Easeup and its agents and affiliates may be recorded for quality control and
                    training purposes.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '28.0pt', marginLeft: '.6pt' }}><span lang="EN">You confirm that the contact information you
                    provide to Easeup, its agents and affiliates, and users, including but not
                    limited to your name, business name, mailing address, email address,
                    residential or business telephone number, and/or mobile phone number, is
                    accurate and true. You confirm that you are the current subscriber or owner of
                    any phone number you provide. You are strictly prohibited from providing a
                    phone number that is not your own. If we discover that any information provided
                    in connection with your registration is false or inaccurate, we may suspend or
                    deactivate your account. If any of your contact information changes, you agree
                    to immediately notify Easeup before the change goes into effect by visiting
                    help.tr.co. If the change involves the ownership of your phone numbers, you can
                    notify Easeup by texting STOP to any text message sent to the retiring phone
                    number.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '29.35pt', textIndent: '-29.25pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>9.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}User Generated Content<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.6pt', marginLeft: '.6pt' }}><span lang="EN">User Generated Content refers to any
                    information or materials that you provide to Easeup or other users using the
                    Easeup platform or as part of a promotional campaign. You are solely
                    responsible for the accuracy and legality of your User Generated Content and
                    Easeup is not responsible for any claims related to it. Easeup acts as a
                    passive conduit for the online distribution and publication of your User
                    Generated Content and has no obligation to monitor or review it. However,
                    Easeup reserves the right to remove or limit User Generated Content that does
                    not comply with the terms of the agreement.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.55pt', marginLeft: '.6pt' }}><span lang="EN">You must ensure that your User Generated
                    Content is not false, misleading, or fraudulent, does not involve the sale of
                    illegal or stolen items, does not infringe on any intellectual property rights
                    or violate any laws, is not defamatory or harmful, and does not contain viruses
                    or malicious code. You also may not claim to be affiliated with or represent
                    Easeup in any way and must not create liability for Easeup or cause it to lose
                    the services of its internet service providers or other partners.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.55pt', marginLeft: '.6pt' }}><span lang="EN">The Easeup platform hosts user-generated
                    content, including reviews and ratings of specific handymen (referred to as
                    "Feedback"). This Feedback reflects the opinions of the users who
                    have posted it and has not been verified or approved by Easeup. Easeup is not
                    responsible or liable for any Feedback or other user-generated content. Easeup
                    encourages users to give objective, constructive, and honest Feedback about
                    their experiences with other users. Easeup may, at its discretion, investigate
                    or consider statements or materials posted by users about Feedback, but it is not
                    obligated to do so. You agree that Feedback allows users to express their
                    experiences and that you will not take action solely because you disagree with
                    the Feedback. If you believe that a review violates the terms of the agreement
                    or the Easeup Ratings and Reviews Policy, you may request its removal by
                    contacting the support team. It is the responsibility of each client to
                    research the qualifications of a specific handyman before booking a task to
                    ensure that they are suitable for the job.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '28.0pt', marginLeft: '.6pt' }}><span lang="EN">Easeup expects users to respect the personal
                    and other rights of others and will identify a user to other users or third
                    parties if they believe their rights have been infringed by user-generated
                    content submitted by that user. If a user believes in good faith that any
                    user-generated content on the Easeup platform is objectionable or infringes
                    their rights or the rights of others, they are encouraged to notify Easeup. If
                    a user discovers usergenerated content that promotes crimes against humanity,
                    incites hatred and/or violence, or concerns child pornography, they must notify
                    Easeup immediately. Notification can be made by contacting Easeup at the
                    address provided.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>10.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Links to Third-Party Websites<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '24.65pt', marginLeft: '.6pt' }}><span lang="EN">The Easeup platform may contain links to
                    third-party websites for reference and convenience. These links do not
                    constitute an endorsement by Easeup or an association with the content or
                    operators of these websites. Easeup is not responsible for the availability,
                    accuracy, content, advertising, products, or services of these websites and
                    does not control them. It is your responsibility to evaluate the information
                    obtained from these websites. Easeup is not responsible for third-party
                    websites and is not liable for any claims related to them. Easeup reserves the
                    right to remove or limit links to third-party websites on the Easeup platform
                    at its discretion. The terms and conditions of use and privacy policy of a
                    third-party website apply to your use of that website. Easeup is not
                    responsible for any liability arising from your use or viewing of third-party
                    websites or material associated with links on the Easeup platform. You agree to
                    hold Easeup harmless from any liability resulting from the use of such links.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '.6pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>11.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Easeup Operates as an Online Marketplace<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.6pt', marginLeft: '.6pt' }}><span lang="EN">Easeup operates as an online marketplace that
                    connects Clients with service providers (Handymen) who wish to perform a
                    variety of Tasks. Easeup does not perform Tasks and does not employ people to
                    perform Tasks. Handymen operate as independent business owners and are
                    customarily engaged in an independently established business of the same nature
                    as that involved in the services performed for Clients through the Easeup
                    Platform. Easeup does not control or direct the Handymen performance of their
                    services or set their work locations, work hours, or terms of work. Handymen
                    provide services </span><span lang="EN" style={{ msoBidiFontFamily: 'Tahoma' }}>under
                        their own name or business name, and not under Easeup’s name. Handymen </span><span lang="EN">provide their own tools and supplies to perform their services; Easeup
                            does not provide the tools or supplies. Handymen are free to maintain a
                            clientele without any restrictions from Easeup and are free to offer and
                            provide their services elsewhere, including through competing platforms.
                            Handymen are free to accept or reject Clients and contracts. Handymen are not
                            penalized for rejecting Clients or contracts, though if Handymen accept a
                            client or contract through the Easeup Platform, they are expected to fulfill
                            their contractual obligations to their client. Handyman's set their own rates
                            for services performed in the Easeup general marketplace, without deduction by
                            Easeup.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '27.95pt', marginLeft: '.6pt' }}><span lang="EN">The Easeup Platform is not an employment agency
                    service or business and Easeup is not an employer of any User. Handymen
                    acknowledge and confirm that they are responsible for exercising their own
                    business judgment in entering into Service Agreements and performing Tasks and
                    that, depending on how they exercise such business judgment, there is a chance
                    for individual profit or loss.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>12.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Easeup Happiness Pledge<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '27.9pt', marginLeft: '.6pt' }}><span lang="EN">The Easeup Happiness Pledge, which applies to
                    the country where the task is performed, is part of this agreement and is
                    incorporated by reference. Handyman's are responsible for determining which
                    categories they are qualified to work in and for communicating the scope of the
                    task directly with their clients. Easeup does not oversee, monitor, or direct
                    how a handyman performs a task and does not assume responsibility for the
                    actions of users. It is the responsibility of the client to confirm that the
                    handyman is qualified to perform the task before it takes place. Easeup is not
                    liable for the actions or omissions of users and does not provide insurance
                    against losses sustained by users. However, Easeup wants users to be satisfied
                    with their experience on the Easeup platform and the Happiness Pledge is in
                    place to encourage continued use of the platform. Payments made under the
                    Happiness Pledge are made at the sole discretion of Easeup and are subject to
                    certain conditions, limitations, and exclusions as described in the Happiness
                    Pledge. The Happiness Pledge does not supersede the terms of the agreement or
                    the Privacy Policy, including the limitations on liability, and these terms
                    take precedence in the event of a conflict with the Happiness Pledge. The Happiness
                    Pledge is not insurance and Easeup is not an insurer.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>13.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Intellectual Property Rights<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '25.1pt', marginLeft: '.6pt' }}><span lang="EN">All text, graphics, editorial content, data,
                    formatting, graphs, designs, HTML, look and feel, photographs, music, sounds,
                    images, software, videos, typefaces, and other content, including Easeup
                    designs, trademarks, and logos (collectively "Proprietary Material")
                    that users see or read through the Easeup platform is owned by Easeup, with the
                    exception of user-generated content, for which users grant Easeup a license to use
                    as described in the agreement. Easeup owns all Proprietary Material and has the
                    rights to the coordination, selection, arrangement, and enhancement of such
                    material. The Proprietary Material is protected by domestic and international
                    laws related to copyrights, patents, and other proprietary rights. Users are
                    not allowed to copy, download, use, redesign, reconfigure, or retransmit any
                    Proprietary Material from the Easeup platform without the express written
                    consent of Easeup and, if applicable, the holder of the rights to the
                    user-generated content. Any unauthorized use of Proprietary Material is
                    prohibited. The service marks and trademarks of Easeup, including Easeup,
                    Easeup for Good, and associated logos, are owned by Easeup. Any other
                    trademarks, service marks, logos, and/or trade names appearing on the Easeup
                    platform are the property of their respective owners. Handymen are not allowed
                    to use Easeup's proprietary marks and logos without the express written consent
                    of the owner.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '.6pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>14.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Copyright Complaints and Copyright Agent<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '.6pt' }}><span lang="EN">Easeup respects the intellectual property of
                    others and expects Users to do the same. If you believe, in good faith, that
                    any materials provided on or in connection with the Easeup Platform infringe
                    upon your copyright or other intellectual property right, please contact
                    support@Easeupgh.tech:</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l3 level1 lfo3' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">A description of the copyrighted
                    work that you claim has been infringed, including the URL (Internet address) or
                    other specific location on the Easeup Platform where the material you claim is
                    infringed is visible. Include enough information to allow Easeup to locate the
                    material, and explain why you think an infringement has taken place; </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l3 level1 lfo3' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">A description of the location
                    where the original or an authorized copy of the copyrighted work exists -- for
                    example, the URL (Internet address) where it is posted or the name of the book
                    in which it has been published; </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l3 level1 lfo3' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Your name, address, telephone
                    number, and e-mail address; </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l3 level1 lfo3' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">A statement by you that you have a
                    good faith belief that the disputed use is not authorized by the copyright
                    owner, its agent, or the law; </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l3 level1 lfo3' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">A statement by you, made under
                    penalty of perjury, that the information in your notice is accurate, and that
                    you are the copyright owner or authorized to act on </span><span lang="EN" style={{ msoBidiFontFamily: 'Tahoma' }}>the copyright owner’s behalf; and</span><span lang="EN"> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '27.9pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l3 level1 lfo3' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Your electronic or physical
                    signature as the owner of the copyright or the person authorized to act on
                    behalf of the owner of the copyright interest. </span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>15.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Confidential Information<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '28.0pt', marginLeft: '.6pt' }}><span lang="EN">You agree to keep all information about Easeup
                    and its business, operations, and properties confidential and not to disclose
                    it to any third party. This includes trade secrets, proprietary information,
                    and any other information that is not generally known to the public or that
                    could be valuable to others. You also agree to protect this information from
                    unauthorized disclosure, use, or transfer. You must immediately notify Easeup
                    if you become aware of any potential unauthorized disclosure or use of this
                    confidential information. You are also required to return all materials
                    containing confidential information to Easeup upon deactivation of your account
                    or termination of this agreement. Confidential information includes technical
                    data, research, product plans, products, services, customers, markets,
                    software, inventions, processes, formulas, technology, designs, drawings,
                    engineering, hardware configuration information, marketing, finances, and other
                    proprietary materials related to Easeup or its business, operations, or
                    properties. It also includes information about Easeup's staff, users, or
                    partners.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>16.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Disclaimer of Warranties<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '17.65pt', marginLeft: '.6pt' }}><span lang="EN">Please note that for Users outside the United
                    States, this Section 17 may be subject to the jurisdiction-specific exemptions
                    set forth in Section 28.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h2 style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '.85pt', textIndent: '0cm' }}><span style={{ fontSize: '14.5pt', msoBidiFontSize: '12.0pt', lineHeight: '107%' }}>A. Use Of The Easeup Platform Is Entirely At Your Own Risk</span><span style={{ fontSize: '14.5pt', msoBidiFontSize: '12.0pt', lineHeight: '107%', fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h2>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '2.35pt', marginBottom: '13.1pt', marginLeft: '.6pt', lineHeight: '105%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ msoBidiFontFamily: 'Tahoma' }}>The Easeup platform is provided
                    "as is" without any warranties or conditions, either expressed or
                    implied. This includes warranties or conditions of merchantability, fitness for
                    a particular purpose, good workmanship, and noninfringement. Easeup does not
                    guarantee the accuracy or completeness of the content provided through the
                    platform or the content of any linked sites. Easeup will not be held
                    responsible or liable for any errors, mistakes, or inaccuracies in the content,
                    personal injury or property damage resulting from access to or use of the
                    platform, access to or use of secure servers and personal or financial
                    information stored on them, or events beyond Easeup's control.</span></b><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.6pt', marginLeft: '.6pt' }}><span lang="EN">Easeup does not warrant, endorse, guarantee or
                    assume responsibility for any service advertised or offered by a third-party
                    through the Easeup Platform or any hyperlinked website or featured in any
                    banner or other advertising, and Easeup will not be a party to or in any way be
                    responsible for any transaction between you and other Users, or you and
                    third-party providers of products or services. As with the purchase of a
                    product or service through any medium or in any environment, you should use
                    your best judgment and exercise caution where appropriate. Without limiting the
                    foregoing, Easeup and Affiliates do not warrant that access to the Easeup
                    Platform will be uninterrupted or that the Easeup Platform will be error-free;
                    nor do they make any warranty as to the results that may be obtained from the
                    use of the Easeup Platform, or as to the timeliness, accuracy, reliability,
                    completeness or content of any Task, service, information or materials provided
                    through or in connection with the use of the Easeup Platform. Easeup and Affiliates
                    are not responsible for the conduct, whether online or offline, of any User.
                    Easeup and Affiliates do not warrant that the Easeup Platform is free from
                    computer viruses, system failures, worms, trojan horses, or other harmful
                    components or malfunctions, including during hyperlink to or from third-party
                    websites. Easeup and Affiliates will implement appropriate technical and
                    organizational measures to ensure a level of security adapted to the risk for
                    any personal information supplied by you.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '26.25pt', marginLeft: '.6pt' }}><span lang="EN">Notwithstanding any feature or service that a
                    client may use to expedite Handyman selection, each Client is responsible for
                    determining the Task and selecting or otherwise approving their Handyman and
                    Easeup does not warrant any goods or services purchased by a client and does
                    not recommend any Handyman. Easeup does not provide any </span><span lang="EN" style={{ msoBidiFontFamily: 'Tahoma' }}>warranties or guarantees regarding any
                        Handyman’s ability, professional accreditation, </span><span lang="EN">registration
                            or licensure.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h2 style={{ marginLeft: '.6pt' }}>B. No Liability<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h2>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '11.35pt', marginLeft: '.6pt' }}><span lang="EN">By agreeing to use the Easeup platform, you
                    acknowledge that Easeup and its affiliates (including corporate partners) are
                    not liable for any claims, damages, expenses, losses, or other liabilities
                    (collectively, "Liabilities") arising from your use or inability to
                    use the platform, including any Liabilities related to the conduct of other
                    users (such as stalking, harassment, violence, or damage to personal property),
                    any disputes with other users, any instructions, advice, or services provided
                    by Easeup and its affiliates, or any damage to your user-generated content. You
                    also agree not to hold Easeup and its affiliates responsible for any such
                    Liabilities.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '-.1pt', marginBottom: '12.25pt', marginLeft: '-.25pt', lineHeight: '99%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ fontSize: '12.5pt', msoBidiFontSize: '12.0pt', lineHeight: '99%', msoBidiFontFamily: 'Tahoma' }}>Easeup and its affiliates (including corporate
                    partners) will not be liable for any direct, indirect, incidental, actual,
                    consequential, economic, special, or exemplary damages (including but not
                    limited to lost profits, loss of data, loss of goodwill, service interruption,
                    computer damage, system failure, failure to store any information or other
                    content maintained or transmitted by Easeup, the cost of substitute products or
                    services, or attorneys' fees and costs) arising from your use of or inability
                    to use the Easeup platform or the task services. This release from liability
                    applies even if Easeup and its affiliates have been advised of the possibility
                    of such damages.</span></b><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '-.1pt', marginBottom: '27.9pt', marginLeft: '-.25pt', lineHeight: '99%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN" style={{ fontSize: '12.5pt', msoBidiFontSize: '12.0pt', lineHeight: '99%', msoBidiFontFamily: 'Tahoma' }}>Easeup and its affiliates specifically deny any
                    liability for any issues that may arise between users of its platform. They
                    also do not accept responsibility for the quality or fitness of any work
                    performed through the platform. If, despite these exclusions, it is determined
                    that Easeup and its affiliates are liable for damages, the total amount of
                    their liability will not exceed the total fees paid by you to Easeup (if you
                    are a client) or the total task payments paid to you by clients (if you are a
                    handyman) in the six months prior to the time the claim arose, as allowed by
                    applicable law.</span></b><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>17.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Indemnification<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '.3pt', marginBottom: '1.0cm', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '100%' }}><span lang="EN">By agreeing to this agreement, you agree to
                    protect and compensate Easeup and its affiliates from any liability that may
                    arise from (i) your use or inability to use the Easeup platform; (ii) your
                    participation in tasks, or your ability to perform or receive payment for
                    tasks; (iii) any violation of this agreement; (iv) any violation of the law or
                    the rights of other users or third parties; (v) your failure to uphold the
                    representations and warranties stated in Section 2; (vi) any content you submit
                    or use through your account on the Easeup platform that may infringe on the
                    intellectual property rights of a third party or be illegal or unlawful; and
                    (vii) if you are a client, the actions or omissions of any client's agents.
                    Easeup has the right to take control of any matter that is subject to your
                    indemnification at its own discretion. You are not allowed to settle any claims
                    or matters without the prior written consent of Easeup.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>18.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Dispute Resolution<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.45pt', marginLeft: '.6pt' }}><span lang="EN">In order to resolve disputes efficiently and at
                    a lower cost, you and Easeup agree to try to resolve any disputes informally
                    for at least 30 days before taking any legal action or seeking out-of-court
                    settlements (such as mediation or arbitration) related to your use of the
                    Easeup platform, your relationship with Easeup, tasks, or this agreement
                    (including previous versions). These informal negotiations will begin when
                    written notice is given. Your address for these notices is the one associated
                    with your account, with a copy sent to the email address you provided to
                    Easeup. This applies to any disputes, controversies, or claims that may arise
                    from or be related to the above-mentioned topics. </span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '27.95pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>19.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}App Store-Sourced Apps<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.35pt', marginLeft: '.6pt' }}><span lang="EN">For any app that is accessed through or
                    downloaded from the Apple App Store (an "App Store-Sourced
                    Application"), the following terms apply:</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l2 level1 lfo4' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">You understand and agree that this
                    agreement is only between you and Easeup, and that Apple is not a party to this
                    agreement except as a third-party beneficiary as described below. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l2 level1 lfo4' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Easeup, not Apple, is solely
                    responsible for the App Store-Sourced Application and its content. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.75pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l2 level1 lfo4' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Your use of the App Store-Sourced
                    Application must follow the App Store Terms of Service. </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.55pt', marginLeft: '.6pt' }}><span lang="EN">You acknowledge that Apple is not responsible
                    for providing maintenance or support for the App Store-Sourced Application. If
                    the App Store-Sourced Application fails to meet any applicable warranty, you
                    can notify Apple and they will refund the purchase price for the App
                    Store-Sourced Application. To the fullest extent permitted by law, Apple will
                    not have any other warranty obligations regarding the App Store-Sourced
                    Application. Any other claims, losses, liabilities, damages, costs or expenses
                    related to the App Store-Sourced Application's failure to meet any warranty
                    will be the responsibility of Easeup, subject to the limitations and exclusions
                    of the warranty set forth in this agreement.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.75pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.6pt', marginLeft: '.6pt' }}><span lang="EN">You and Easeup acknowledge that Apple is not
                    responsible for addressing any claims you or any third parties may have
                    regarding the App Store-Sourced Application or your possession and use of the
                    App Store-Sourced Application. This includmes, but is not limited to:</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l2 level1 lfo4' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Product liability claims </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l2 level1 lfo4' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Claims that the App Store-Sourced
                    Application does not meet any applicable legal or regulatory requirements </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '36.85pt', textIndent: '-18.0pt', msoList: 'l2 level1 lfo4' }}>{/*[if !supportLists]*/}<span lang="EN" style={{ fontSize: '10.0pt', lineHeight: '103%', fontFamily: '"Arial",sans-serif', msoFareastFontFamily: 'Arial' }}><span style={{ msoList: 'Ignore' }}>•<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN">Claims arising under consumer
                    protection or similar legislation </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.75pt', marginLeft: '.6pt' }}><span lang="EN">In the event that a third party claims that the
                    App Store-Sourced Application or your possession and use of it infringes on
                    their intellectual property rights, Easeup (and not Apple) will be responsible
                    for investigating, defending, settling, and addressing the claim, to the extent
                    required by this agreement.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '13.55pt', marginLeft: '.6pt' }}><span lang="EN">You and Easeup acknowledge and agree that Apple
                    and its subsidiaries are third-party beneficiaries of this agreement in
                    relation to your license of the App Store-Sourced Application. This means that
                    upon your acceptance of the terms and conditions of this agreement, Apple has
                    the right (and is deemed to have accepted the right) to enforce this agreement
                    against you as a third-party beneficiary in relation to your use of the App
                    Store-Sourced Application.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '27.45pt', marginLeft: '.6pt' }}><span lang="EN">In addition to other terms in this agreement,
                    you must comply with all applicable thirdparty terms of agreement when using
                    the App Store-Sourced Application.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>20.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Licensing<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '25.05pt', marginLeft: '.6pt' }}><span lang="EN">Handymen are solely responsible for ensuring
                    that they have any necessary licenses, permits, or registrations before
                    providing services or completing tasks. Some types of tasks and services may be
                    prohibited by law, and it is the handyman's responsibility to avoid these
                    prohibited tasks and services. Failure to do so could result in fines or other
                    legal consequences. If you have questions about how local, state, provincial,
                    or national laws apply to tasks and services on the Easeup platform, you should
                    seek legal advice. Easeup is not responsible for overseeing, directing, or
                    controlling a handyman's work or the performance of tasks. It is up to the
                    client to determine if a handyman has the necessary skills and qualifications
                    to complete a specific task. It is also the client's responsibility to check if
                    certain tasks require a licensed or registered professional, and to discuss any
                    potential hazards or obstacles at the task location with the handyman.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '.6pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>21.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Changes to this Agreement and the Easeup
                    Platform<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '27.5pt', marginLeft: '.6pt' }}><span lang="EN">Easeup reserves the right to make changes to
                    any part of this Agreement, including the Terms of Service, Privacy Policy, and
                    Happiness Pledge, at any time and without notice. This includes modifying,
                    adding to, or deleting any terms and conditions, as well as reviewing,
                    improving, modifying, or discontinuing the Easeup platform or any content or
                    information available through the platform. Easeup may also limit access to
                    certain features or restrict access to the platform entirely without any
                    liability. While Easeup will try to notify you of significant changes to this
                    Agreement by email, it is not responsible if it fails to do so. If any future
                    changes to this Agreement are unacceptable to you or cause you to be in
                    violation of the Agreement, you must deactivate your account and stop using the
                    Easeup platform. By continuing to use the platform after any changes to this
                    Agreement, you are accepting all such changes, unless prohibited by law in your
                    jurisdiction.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>22.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}No Rights of Third Parties<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '12.8pt', marginLeft: '.6pt' }}><span lang="EN">The provisions of this Agreement are intended
                    for the benefit of the parties and their permitted successors and assigns only,
                    and should not be interpreted as granting any rights to third parties (except
                    for third party beneficiaries specified in Section 20). This Agreement does not
                    give any person or entity other than the user any interest, claim, remedy,
                    liability, reimbursement, or any other legal action related to or connected to
                    any agreement or provision in this Agreement. The terms of this Agreement are
                    not enforceable by anyone who is not a party to this Agreement, although a
                    client's agent may act on behalf of their client.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '25.0pt', marginLeft: '.85pt', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <h1 style={{ marginLeft: '.6pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>23.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Notices and Consent to Receive Notices
                    Electronically<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}> </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '28.0pt', marginLeft: '.6pt' }}><span lang="EN">You consent to receiving any agreements,
                    notices, disclosures, and other communications ("Notices") related to
                    this Agreement electronically, such as by email or by posting them on the
                    Easeup platform. You agree that all Notices that we provide electronically
                    satisfy any legal requirement for them to be in writing. Unless otherwise
                    stated in this Agreement, all Notices under this Agreement will be considered
                    to have been properly given when received if delivered in person or sent by
                    certified or registered mail with a return receipt requested, when
                    electronically confirmed if transmitted by fax or email, or on the day they are
                    marked as delivered by an overnight delivery service's tracking information if
                    sent for next day delivery by a recognized overnight delivery service. </span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <h1 style={{ marginLeft: '44.3pt', textIndent: '-44.2pt', msoList: 'l4 level1 lfo5' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>24.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Consent to Electronic Signatures<span style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '3.2pt', marginBottom: '.15pt', marginLeft: '.6pt' }}><span lang="EN">By agreeing to this clause, you are
                    acknowledging that your electronic signature has the same legal effect as a
                    handwritten signature and will be treated as such in any legal proceedings. You
                    are also agreeing that the use of a keypad, mouse, or other device to select an
                    item or action is considered equivalent to a handwritten signature. This means
                    that any actions you take on the Easeup platform through these means will be
                    legally binding and enforceable. It is important to carefully consider the
                    implications of this clause before agreeing to it. If you have any questions or
                    concerns about the legal effects of your electronic signature, you should seek
                    the advice of an attorney or legal expert.</span><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}>
                    </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '12.0pt', marginLeft: '.85pt', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontFamily: '"Times New Roman",serif', msoFareastFontFamily: '"Times New Roman"' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '.85pt', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN" style={{ fontSize: '11.0pt', msoBidiFontSize: '12.0pt', lineHeight: '107%', fontFamily: '"Calibri",sans-serif', msoFareastFontFamily: 'Calibri' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
            </div>
            <Footer />
        </Layout>
    );
}
