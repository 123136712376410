export default function Price({ hasBg }) {
  return (
    <>
      <div className="overflow-hidden">
        <section
          id="howto"
          className={`package-section ${hasBg ? "gray-light-bg" : "background-shape-right"
            } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                    How Easeup Works<br />

                  </h2>
                  <p className="lead">
                    Easily connect with a handyman for all your house chores and home repairs on Easeup platform. App available on Google Play Store.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Create a Plan</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/plan.png"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <p>Provide the details of the job and book an appointment. </p>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center popular-price single-pricing-pack p-5">
                  <h5 className="mb-2">Choose a Worker</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/choose.png"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <p>From the list of services, select the professional suitable for the job</p>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md">
                <div className="card text-center single-pricing-pack p-5">
                  <h5 className="mb-2">Get the job done</h5>
                  <div className="pricing-img mt-3 mb-4">
                    <img
                      src="assets/img/task-done.png"
                      alt="pricing img"
                      className="img-fluid"
                    />
                  </div>

                  <div className="card-body p-0">
                    <ul className="list-unstyled text-sm pricing-feature-list">
                      <p>Worker arrives at your door and get things done.</p>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 text-center">
              <p className="mb-2">
                If you need custom services or Need more?
                <a href="#/" className="color-secondary">
                  {" "}
                  Contact us{" "}
                </a>
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
