import React from "react";
import { Link } from 'react-router-dom';
import { Button } from "react-bootstrap";
export default function ThankYou() {
  return (
    <>
      <section
        className="hero-section ptb-100 background-img full-screen"
        style={{
          background:
            "url('assets/img/hero-bg-1.jpg')no-repeat center center / cover",
        }}
      >
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-md-9 col-lg-7">
              <div className="hero-content-left text-white text-center">
                <h1 className="text-white">Thank You !</h1>

                <p className="lead">
                  Thank you for choosing EaseUp! We're thrilled to have been able to assist you and make your life a little bit easier.
                </p>

                <Link to="/">
                  <Button className="btn solid-btn">Go Back Home</Button>
                </Link>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
