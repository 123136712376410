import React from "react";

export default function LargerHeadingSection(props) {
    return (
        <>
            <div data-baseweb="block" id="animation-wrapper" class="css-PKJb">
                <section data-baseweb="block" id="" class="css-kdGSLU">
                    <div data-baseweb="block" class="css-hazmlH">
                        <div data-baseweb="block" class="css-fqRRpq"></div>
                        <div data-baseweb="block" class="css-ctWFlC">
                            <div data-baseweb="block" class="container-inner css-igbwHX">
                                <div data-baseweb="block" class="css-bVFrLX">
                                    <div data-baseweb="block" class="css-hFtrXB">
                                        <div class="css-kwsvKZ">
                                            <div class="css-cYTjpz">
                                                <div data-baseweb="block" id="animation-wrapper" class="css-PKJb">
                                                    <div class="css-ggxDdj">
                                                        <div class="css-hPnljU">
                                                            <h1 class="css-bJAboq">About us</h1>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div data-baseweb="block" id="animation-wrapper" class="css-PKJb"></div>
                                            </div>
                                        </div>
                                        <div data-baseweb="block" id="animation-wrapper" class="css-PKJb">
                                            <div class="css-hUcgLG"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}