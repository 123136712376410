import React from "react";

const Promo = () => {
  return (
    <>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5">
                <h2>Become a Worker with Easeup</h2>
                <p className="lead">

                  You can register today as a service provider with EaseUp, where you'll have a list of available services to showcase your skills and grow your business.

                </p>
              </div>
            </div>
          </div>
          <div className="row equal">
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <img
                    src="assets/img/flexibility.png"
                    alt="icon"
                    className="img-fluid"
                  />
                </div>
                <h5>Flexible Work Schedule</h5>
                <p>Work when, where, and how you decide. Provide services in more than 20 categories and maintain a flexible schedule and work environment.</p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <img
                    src="assets/img/rate2.jpg"
                    alt="icon"
                    className="img-fluid"
                  />
                </div>
                <h5>Set your own rate</h5>
                <p>
                  At EaseUp, we believe in giving our handymen the freedom to set their own rates and manage their own finances. You can receive payment directly from your clients, whether through cash or electronic payment, and get paid the same day.
                </p>
              </div>
            </div>
            <div className="col-md-4 col-lg-4">
              <div className="single-promo single-promo-hover single-promo-1 rounded text-center white-bg p-5 h-100">
                <div className="circle-icon mb-5">
                  <img
                    src="assets/img/grow2.png"
                    alt="icon"
                    className="img-fluid"
                  />
                </div>
                <h5>Grow your Business</h5>
                <p>
                  Our platform connects you with clients in your local area and offers various marketing strategies to promote your services. This allows you to focus on your area of expertise and provide top-quality service to your clients.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Promo;
