import React, { useState } from 'react';
import { Table, Modal, Button } from 'react-bootstrap';

const Cta = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const platform = [
    {
      title: "Android",
      placeholder: "For Clients",
      name: "Easeup WorkSync",
      description: "https://play.google.com/store/apps/details?id=com.io.easeup",
    },

    {
      title: "Android",
      placeholder: "For Workers",
      name: "Easeup WorkBuddy",
      description: "https://play.google.com/store/apps/details?id=com.easeup.worker",
    },
    {
      title: "iOS",
      placeholder: "For Clients",
      name: "Easeup WorkSync",

      description: "https://apps.apple.com/us/app/easeup/id1572184193",
    },
    {
      title: "iOS",
      placeholder: "For Workers",
      name: "Easeup WorkBuddy",

      description: "https://apps.apple.com/us/app/easeup/id1572184193",
    },
  ];

  return (
    <>
      <section
        className="download-section pt-100 background-img"
        id="app"
        style={{
          background:
            "url('assets/img/gardener.jpg') no-repeat center center / cover",
        }}
      >
        <div className="container" >
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="download-content text-white pb-100">
                <h2 className="text-white">
                  Maintain your home in a day
                </h2>
                <p className="lead">
                  Download our app now on the App Store or Google Play Store to enjoy the convenience of finding skilled workers for your home or office tasks. With our easy-to-use platform, you can quickly connect with verified professionals and get the job done right, without any hassle.                </p>


                <Button className="btn google-play-btn mr-3 " onClick={handleShow}>
                  <span className="ti-download"> </span> Download Now
                </Button>

              </div>
            </div>
            <div className="col-md-4">
              <div className="download-img d-flex align-items-end">
                <img
                  src="assets/img/phone-hand.png"
                  alt="download"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header>
            <Modal.Title>Unlock the Power - Download the App Today!</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <p className="lead text-justify">
              Our apps are available on Google Playstore and the Appstore
            </p>
            <Table striped bordered info table-info hover >
              <thead>
                <tr>
                  <th>#</th>
                  <th scope="col">Platform</th>
                  <th scope="col">App</th>
                  <th scope="col">Link</th>
                </tr>
              </thead>
              <tbody>
                {platform.map((feature, index) => (
                  <tr key={index}>
                    <th scope="row">{index + 1}</th>
                    <td>{feature.title}</td>
                    <td>{feature.name}</td>
                    <td>
                      <a href={feature.description}>{feature.placeholder}</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

          </Modal.Footer>
        </Modal>

      </section>
    </>
  );
};

export default Cta;
