import React from "react";

export default function MissionStatement(props) {

    return (
        <>
            <section data-baseweb="block" id="" class="css-dDHHCg">
                <div data-baseweb="block" class="css-hazmlH">
                    <div data-baseweb="block" class="css-gDdGhK"></div>
                    <div data-baseweb="block" class="css-ofGld">
                        <div data-baseweb="block" class="container-inner css-bPNWqZ">
                            <div data-baseweb="block" class="css-bVFrLX">
                                <div data-baseweb="block" class="css-jBlUms">
                                    <div class="css-cPlfjU">
                                        <div class="css-dkkdtH">
                                            <div data-baseweb="block" id="" class="css-PKJb">
                                                <div class="css-eJDKay">
                                                    <div class="css-hPnljU">
                                                        <h2 class="css-fOwPKV">We reimagine the way the world moves for the better</h2>
                                                    </div>
                                                </div>
                                            </div>
                                            <div data-baseweb="block" id="" class="css-PKJb">
                                                <div class="css-ggxDdj">
                                                    <div class="css-kqHVrb">
                                                        <p class="cmln__paragraph">Movement is what we power. It’s our lifeblood. It runs
                                                            through our veins. It’s what gets us out of bed each morning. It pushes us to
                                                            constantly reimagine how we can move better. For you. For all the places you want to
                                                            go. For all the things you want to get. For all the ways you want to earn. Across
                                                            the entire world. In real time. At the incredible speed of now.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div data-baseweb="block" id="collapsess" class="css-PKJb"></div> */}
                                        </div>
                                    </div>
                                </div>
                                <div data-baseweb="block" class="css-fYgWEH">
                                    <ul data-baseweb="accordion" class="css-hSsFWv">
                                        <li class="css-iqCRtL">
                                            <div role="button" aria-expanded="false" class="css-kmayiX" data-bs-toggle="collapse" href="#collapses" aria-controls="collapses">Read our full
                                                mission statement<a href="" >
                                                    <svg viewBox="0 0 24 24" title="Expand" size="16" class="css-jkKeGf">
                                                        <g class="css-fgTMrg">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12Z">
                                                            </path>
                                                        </g>
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M6 12C6 11.4477 6.44772 11 7 11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H7C6.44772 13 6 12.5523 6 12Z">
                                                        </path>
                                                    </svg></a></div>
                                            <div class="css-cXVGIX collapse">
                                                <div class="css-kElYAN collapse">
                                                    <div class="css-dkkdtH collapse">
                                                        <div data-baseweb="block" id="collapses" class="css-PKJb collapse">
                                                            <div class="css-ggxDdj">
                                                                <div class="css-kqHVrb">
                                                                    <p class="cmln__paragraph">We are Uber. The go-getters. The kind of people who
                                                                        are relentless about our mission to help people go anywhere and get anything
                                                                        and earn their way. Movement is what we power. It’s our lifeblood. It runs
                                                                        through our veins. It’s what gets us out of bed each morning. It pushes us to
                                                                        constantly reimagine how we can move better. For you. For all the places you
                                                                        want to go. For all the things you want to get. For all the ways you want to
                                                                        earn. Across the entire world. In real time. At the incredible speed of now.
                                                                    </p>
                                                                    <p class="cmln__paragraph">We are a tech company that connects the physical and
                                                                        digital worlds to help make movement happen at the tap of a button. Because we
                                                                        believe in a world where movement should be accessible. So you can move and
                                                                        earn safely. In a way that’s sustainable for our planet. And regardless of
                                                                        your gender, race, religion, abilities or sexual orientation, we champion your
                                                                        right to move and earn freely and without fear. Of course, we haven’t always
                                                                        got it right. But we’re not afraid of failure, because it makes us better,
                                                                        wiser and stronger. And it makes us even more committed to do the right thing
                                                                        by our customers, local communities and cities, and our incredibly diverse set
                                                                        of international partners. </p>
                                                                    <p class="cmln__paragraph">The idea for Uber was born on a snowy night in Paris
                                                                        in 2008, and ever since then, our DNA of reimagination and reinvention carries
                                                                        on. We’ve grown into a global platform powering flexible earnings and the
                                                                        movement of people and things in ever expanding ways. We’ve gone from
                                                                        connecting trips on 4 wheels to 2 wheels to 18-wheel freight deliveries. From
                                                                        takeaway meals to daily essentials to prescription drugs to just about
                                                                        anything you need at any time and earning your way. From drivers with
                                                                        background checks to real-time verification, safety is a top priority every
                                                                        single day. At Uber, the pursuit of reimagination is never finished, never
                                                                        stops, and is always just beginning.</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}