import React from "react";
import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";

// Create styles

export default function PrivacyPolicy() {

    return (
        <Layout>
            <Navbar />

            <div className="WordSection1 container p-5 mt-5 text-justify">
                <p className="MsoNormal"><b><span lang="EN-US" style={{ fontSize: '24.0pt', lineHeight: '103%' }}>Privacy Policy</span></b></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '27.4pt', marginLeft: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><b style={{ msoBidiFontWeight: 'normal' }}><span lang="EN-US" style={{ fontSize: '8.5pt', msoBidiFontSize: '12.0pt', lineHeight: '107%' }}>Last
                    Updated: December 30, 2022 </span></b></p>
                <h1 style={{ marginLeft: '22.75pt', textIndent: '-23.5pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Introduction </h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '24.65pt', marginLeft: '-.25pt' }}><span lang="EN-US">This privacy statement explains how Easeup
                    will use and store your personal information on their platform. It also
                    explains your legal rights in relation to this information. By using the Easeup
                    platform, you are agreeing to have read and understood the privacy policy and
                    terms of service. These documents outline the rules for using the Easeup
                    platform and how Easeup will handle your information. </span></p>
                <h1 style={{ marginLeft: '22.75pt', textIndent: '-23.5pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}General Terms </h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">In this Privacy Policy, </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.6pt', marginLeft: '36.0pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN-US"><span style={{ msoList: 'Ignore' }}>1.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN-US">Easeup may be referred to as
                    "Easeup," "we," "our," or "us." </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '36.0pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN-US"><span style={{ msoList: 'Ignore' }}>2.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN-US">A user of the Easeup platform
                    may be referred to as "User," "Users," "you,"
                    "your," or "Client" and "Handyman," depending on
                    the context. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '36.0pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN-US"><span style={{ msoList: 'Ignore' }}>3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN-US">The community platform offered
                    on the Easeup website (<u style={{ textUnderline: 'black' }}>www.easeupgh.tech</u>)
                        is referred to as the "Site," while the mobile application (for
                        either iOS or Android) is referred to as the "App." </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '0cm', marginLeft: '36.0pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN-US"><span style={{ msoList: 'Ignore' }}>4.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN-US">The Easeup website, mobile
                    application, and any associated information and communication are collectively
                    referred to as the "Easeup Platform." </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '24.6pt', marginLeft: '36.0pt', textIndent: '-18.0pt', msoList: 'l0 level1 lfo1' }}>{/*[if !supportLists]*/}<span lang="EN-US"><span style={{ msoList: 'Ignore' }}>5.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span lang="EN-US">The "Terms of
                    Service" refers to the terms and conditions for using the Easeup platform,
                    which can be found at a specified link. This Privacy Policy is considered a
                    part of the Terms of Service. </span></p>
                <h1 style={{ marginLeft: '-.25pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>3.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Information Collection Information you provide
                    to <span className="GramE">Easeup</span> </h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">Easeup collects certain personally
                    identifiable information about you, including information that can be used to
                    identify, describe, or contact you (“Personal Information”). This may include
                    your name, address, phone number, email address, and other identifying
                    information. Easeup may also collect billing data, such as your payment
                    instrument number, expiration date, and security code, as needed to process
                    your payments. For Handymen, Easeup may collect personal information such as
                    date of birth, address, national identification number (if applicable), and the
                    results of basic criminal record checks (to the extent permitted by law). </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">Easeup may also collect financial
                    information, including credit card numbers, bank routing numbers, tax
                    information, and taxpayer identification numbers, <span className="GramE">in order to</span>
                    set up payment accounts for Handymen and to facilitate payments between Clients
                    and Handymen, as well as to pay fees to Easeup. To keep financial data secure,
                    Easeup has contracted with a third party to maintain and process payment
                    information. This is done <span className="GramE">in order to</span> operate the
                    Easeup platform and to fulfill contracts with users, as necessary for our
                    legitimate interests in providing our platform and services. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">Certain optional offerings of the Easeup platform,
                    such as newsletters, surveys, and contests, may require you to provide personal
                    information. If you choose to participate in these offerings, Easeup may use
                    your data to send you tailored newsletters and other communications or to
                    operate and manage the survey, contest, or other offering. This is done in
                    connection with our legitimate interest in promoting our business and the
                    Easeup platform. You can opt out of receiving marketing communications from
                    Easeup by following the instructions in section 7, "Your Rights and
                    Choices." </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">Easeup collects information related to
                    your employment and education history, transcripts, skills, and references <span className="GramE">in order to</span> consider your job application for open
                    positions. This information is collected as necessary to evaluate your
                    application. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">Easeup collects personal information that
                    you may choose to send in emails or chat messages asking about the Easeup
                    platform <span className="GramE">in order to</span> respond to your inquiry. Easeup
                    also collects the content of any messages you exchange with other users through
                    the Easeup platform, such as through the chat function. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">When you register an account with Easeup,
                    certain information is required <span className="GramE">in order to</span> provide
                    services through the platform. For example, if you are a Client, Easeup will
                    collect your name, email address, and location information <span className="GramE">in
                        order to</span> create and manage your account and facilitate booking requests.
                    Additional information, such as details about the task you are seeking and
                    billing data, may also be collected. If you are a Handyman, Easeup will collect
                    your name, email address, phone number, and location information <span className="GramE">in order to</span> create and manage your account and facilitate
                    communication with Clients. Information about your tasks, rates, skills,
                    identity, and financial information may also be collected. </span></p>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '12.8pt', marginLeft: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN-US"><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '24.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">Easeup may collaborate with external
                    agents to conduct identity and criminal background checks on Handymen if it is
                    legal and necessary to protect our users and maintain the trustworthiness of
                    the Easeup platform. These checks may be performed to advance our legitimate
                    interests in ensuring safety and integrity. </span></p>
                <h1 style={{ marginLeft: '22.75pt', textIndent: '-23.5pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>4.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Information Easeup Collects Automatically </h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '24.6pt', marginLeft: '-.25pt' }}><span lang="EN-US">When you use the Easeup platform, we may
                    automatically collect certain information about your activity on the platform,
                    your device, and your location. This information may include data about the
                    features you use, the pages you visit, emails and ads you view, the time of day
                    you browse, and <span className="GramE">your</span> referring and exit pages. It may
                    also include information about your device, such as the type of device or
                    browser you use, your device's operating system, your internet service
                    provider, and device identifiers like IP address and Ad Id. We may use this
                    device information to monitor the geographic regions from which users access
                    the Easeup platform and for security and fraud prevention purposes. We may also
                    collect location data, such as imprecise location data (like location derived
                    from an IP address) or precise location data (like latitude/longitude data)
                    with your consent. When you access the Easeup platform through a native mobile
                    app, we may use GPS technology to determine your location with your consent. We
                    may also use cookies and similar technologies, which are described in our
                    cookie policy. </span></p>
                <h1 style={{ marginLeft: '22.75pt', textIndent: '-23.5pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>5.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Use of Information by Easeup </h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">We use information for various business
                    and commercial purposes, as described in this privacy policy. These purposes
                    may include operating and providing access to the Easeup platform, billing and
                    fraud prevention, conducting identity and criminal background checks on users
                    (where permitted by law), analyzing platform usage to improve the Easeup
                    platform, contacting users and delivering marketing communications with their
                    consent, providing customer support, conducting internal market research,
                    troubleshooting problems, assisting users in resolving disputes, enforcing our
                    terms of service, and as otherwise stated in the agreement. These activities
                    may be carried out to fulfill our contracts with users, for our legitimate
                    interests in ensuring a safe and secure environment, improving the platform,
                    engaging with users, and promoting our business, and to comply with legal
                    obligations. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '24.6pt', marginLeft: '-.25pt' }}><span lang="EN-US">Easeup may use interest-based advertising
                    and tracking technologies to display ads to users on the Easeup platform and
                    other websites and services. We may also use your information to conduct market
                    analysis and offer services to users who may be interested in them. We may use
                    your cell phone number to call or send text messages for notifications about
                    tasks, marketing purposes (with your consent when required by law), and to
                    administer the Easeup platform. You may opt out of these messages on your
                    mobile device and be charged for standard SMS and per-minute fees by your
                    mobile carrier. Calls to or from Easeup or its third-party agents may be
                    recorded for quality control and training purposes and may contain personal
                    information. These activities may be carried out as necessary for our
                    legitimate interests in providing an engaging and relevant experience,
                    promoting our services, and growing our business. For more information about
                    our advertising and cookie policies, please see the "Your Rights and
                    Choices" section or Section 7 of our terms of service. </span></p>
                <h1 style={{ marginLeft: '22.75pt', textIndent: '-23.5pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>6.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Information Sharing </h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">We may share the information we collect
                    about you with third-party agents who process information on our behalf for our
                    business purposes. These agents may include vendors and service providers that
                    support the operation of the Easeup platform, such as email origination,
                    identity checks, criminal background checks (where permitted by law), fraud
                    prevention and detection, receipt, invoice, and customer relationship
                    management services, data analytics, marketing and advertising, website
                    hosting, communications services, technical support, financial transaction
                    fulfillment, and chargeback and collection services. We require these
                    third-party agents to only use your information for the specific services they
                    are providing and prohibit them from retaining, using, or disclosing your
                    information for any other purposes. However, we may allow them to use
                    non-identifying information (such as aggregated or de-identified data) for any
                    purpose except as prohibited by applicable law. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">Some content on the Easeup platform may be
                    sponsored or presented by other companies. If you access the Easeup platform
                    through a co-branded version or participate in one of our partner programs, we
                    may share information about your use of the platform with the partner <span className="GramE">in order to</span> offer an integrated platform and evaluate the
                    partner program. We may also share your personal information with our partners
                    to receive additional information about you or create offers that may be of
                    interest to you. It is important to review a partner's privacy policy before
                    sharing information about yourself. If you do not want these partners to have
                    your personal information, you can choose not to participate in the partner
                    program. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">We may share information as required by
                    law and for administrative purposes when you enter a sweepstakes, contest, or
                    other promotion. By entering a promotion, you agree to the official rules
                    governing that promotion and may allow the sponsor and other entities to use
                    your name, voice, and/or likeness in advertising or marketing materials, unless
                    prohibited by applicable law. We may contact you with information about special
                    events, activities, promotions, contacts, submission opportunities, and
                    programs if you opt in to receive such communications. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">We may also use matched ads by sharing
                    personal information with another party or incorporating a pixel from another
                    party into our own sites for advertising purposes. For example, we may
                    incorporate the Facebook pixel on our sites and share your email address with
                    Facebook as part of our use of Facebook Custom Audiences. We facilitate contact
                    between clients and handymen and may share one user's contact information with
                    other users or the recipient user's legal or authorized representative <span className="GramE">in order to</span> resolve an investigation or dispute related to
                    an interaction between users or facilitate the performance of a task. This
                    exchange of information is necessary for the operation of the Easeup platform. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">Easeup and its third-party agents may
                    disclose your personal information or user-generated content to courts, law
                    enforcement agencies, governmental or public authorities, tax authorities, or
                    authorized third parties if and to the extent required or permitted by law or
                    if disclosure is reasonably necessary to comply with <span className="SpellE">Easeup's</span>
                    legal or regulatory obligations; respond to a court order, warrant, or
                    subpoena; investigate alleged criminal or illegal activity; or address any
                    activity that may expose Easeup to legal or regulatory liability. In certain
                    jurisdictions, Easeup and its third-party agents may also disclose information
                    such as user contact details, identification information, transaction dates and
                    amounts, license and permit status, and tax identification numbers to relevant
                    governmental authorities if required by law. Easeup may also disclose personal
                    information if it believes it is necessary to protect the rights of its users,
                    the <span className="GramE">general public</span>, or Easeup or its affiliates in the
                    event of a compromise to a user's account or in the event of an emergency. In
                    the event of a merger, acquisition, or business combination, Easeup may share
                    information about its users. Information in a user's profile, including skills,
                    expertise, pay rates, and feedback/rating information, may be visible to all
                    users and the <span className="GramE">general public</span>. If a user posts a task,
                    the contents of the listing will be visible to the handymen selected for the
                    task. </span></p>
                <h1 style={{ marginLeft: '22.75pt', textIndent: '-23.5pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>7.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}Your Rights and Choices </h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">You have the option to stop receiving
                    promotional messages from us, including those related to the Easeup Platform,
                    and request for your personal information to be removed from our databases or
                    deactivate your account by accessing the "Account" tab on the Site or
                    App or by contacting us. </span></p>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '24.4pt', marginLeft: '-.25pt' }}><span lang="EN-US">During the registration process for
                    Easeup, you have the option to choose <span className="GramE">whether or not</span>
                    you want to receive marketing emails from the company. You can edit this
                    preference at any time by logging in and accessing the "Account" tab
                    and selecting "Notifications". In addition to email marketing, you
                    also have the option to receive push notifications through the app, which can
                    be configured in the settings of your mobile device. You have the right to
                    update and correct any inaccuracies on your Easeup profile at any time by
                    accessing the "Account" tab. Some information, such as your age,
                    cannot be altered by you and may require contacting Easeup at <u style={{ textUnderline: 'black' }}>privacy@easeup.net</u> to make corrections. You
                    can opt out of receiving promotional emails from Easeup by accessing the
                    "Account" tab in your profile or by clicking the unsubscribe link in
                    any promotional email. You can also opt out of receiving promotional text
                    messages by following the instructions provided in those messages to text the
                    word "STOP". Please note that this opt-out is only applicable to the
                    phone number used and will not affect any future subscriptions. If you opt out
                    of certain communications, you may still receive non-promotional emails from
                    Easeup regarding your account, tasks, transactions, servicing, or the ongoing
                    business relationship with the company. If you receive unsolicited promotional
                    emails from Easeup domain, you can report them by contacting the company. </span></p>
                <h1 style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.75pt', marginLeft: '22.75pt', textIndent: '-23.5pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>8.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span className="SpellE"><span style={{ fontWeight: 'normal' }}>Easeup's</span></span><span style={{ fontWeight: 'normal' }}> Information
                    Retention Policy </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '28.85pt', marginLeft: '-.25pt' }}><span lang="EN-US">We keep your personal data on file <span className="GramE">as long as</span> you are a user of our services in order to
                    fulfill our obligations to you and to comply with legal requirements. We may
                    also store data that cannot be directly linked to your identity, such as
                    information connected to a randomly generated identifier. This data is used for
                    research and to improve and develop our services, and we take measures to
                    ensure that it cannot be traced back to you. The specific length of time that we
                    retain personal data is outlined in Exhibit A. </span></p>
                <h1 style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.75pt', marginLeft: '22.75pt', textIndent: '-23.5pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>9.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span className="SpellE"><span style={{ fontWeight: 'normal' }}>Easeup’s</span></span><span style={{ fontWeight: 'normal' }}> Security of
                    Collected Information </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '24.35pt', marginLeft: '-.25pt' }}><span lang="EN-US">Your Easeup account is password-protected,
                    and only you and <span className="SpellE">authorised</span> Easeup staff should have
                    access to your account information. To maintain this protection, do not share
                    your password with anyone. If you use a shared computer, make sure to sign out
                    of your Easeup account and close the browser window before someone else logs
                    on. This will help protect your information entered on public terminals from
                    being accessed by third parties. Easeup takes reasonable security precautions
                    to protect your data from loss, theft, misuse, unauthorized access, disclosure,
                    alteration, and destruction. These measures include administrative, physical,
                    and technical safeguards, as well as staff dedicated to maintaining privacy and
                    security. However, it's important to note that internet transmissions are not
                    completely secure, and we cannot guarantee the security of information about
                    you. </span></p>
                <h1 style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.75pt', marginLeft: '34.55pt', textIndent: '-35.3pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>10.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span style={{ fontWeight: 'normal' }}>Notification of
                    Changes </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '28.9pt', marginLeft: '-.25pt' }}><span className="SpellE"><span lang="EN-US">Easeup's</span></span><span lang="EN-US"> Privacy Policy is reviewed and updated on a regular basis as
                    needed. It may change as Easeup updates and expands its services. Easeup will
                    try to notify you of any significant changes to the policy by email. We
                    recommend reviewing the Privacy Policy periodically to stay informed of any
                    updates. </span></p>
                <h1 style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.75pt', marginLeft: '34.55pt', textIndent: '-35.3pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>11.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span style={{ fontWeight: 'normal' }}>Children’s
                    Privacy </span></h1>
                <p className="MsoNormal" align="left" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '24.85pt', marginLeft: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '99%' }}><span lang="EN-US">This service is not intended for children
                    under 18 years of age. If you are a parent or guardian and believe that we have
                    collected information from your child in an unlawful manner, please contact us
                    so that we can remove the data. We do not knowingly collect or "sell"
                    the personal information of minors under 18 years old who are residents of
                    Ghana. </span></p>
                <h1 style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '.75pt', marginLeft: '34.55pt', textIndent: '-35.3pt', msoList: 'l1 level1 lfo2' }}>{/*[if !supportLists]*/}<span style={{ msoBidiFontSize: '23.5pt', lineHeight: '107%', msoBidiFontWeight: 'bold' }}><span style={{ msoList: 'Ignore' }}>12.<span style={{ font: '7.0pt "Times New Roman"' }}>&nbsp;&nbsp;
                </span></span></span>{/*[endif]*/}<span style={{ fontWeight: 'normal' }}>Contacting Us </span></h1>
                <p className="MsoNormal" style={{ marginTop: '0cm', marginRight: '0cm', marginBottom: '13.5pt', marginLeft: '-.25pt' }}><span lang="EN-US">If you have any questions or concerns
                    about this Privacy Policy, the way we or our third-party agents handle your
                    personal information, the practices of our site, your interactions with the
                    Easeup platform, or if you experience technical problems, you can contact us
                    for assistance. <span className="SpellE">Easeup's</span> staff will respond to all
                    mail or email from users who have questions about privacy, including inquiries
                    about the types of personal information stored in our databases and requests to
                    know, delete, or correct that information. </span></p>
                <p className="MsoNormal" align="left" style={{ margin: '0cm', textAlign: 'left', textIndent: '0cm', lineHeight: '107%' }}><span lang="EN-US" style={{ fontSize: '11.0pt', msoBidiFontSize: '12.0pt', lineHeight: '107%', fontFamily: '"Calibri",sans-serif', msoFareastFontFamily: 'Calibri' }}><span style={{ msoSpacerun: 'yes' }}>&nbsp;</span></span></p>
            </div>

            <Footer />
        </Layout>
    );
}
